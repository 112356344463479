import PLP from "../../../components/plp/plp";
import CustomMetas from "../../../components/custom-metas";
import getSearchData from "../../../helpers/search";


const SearchPage = (props) => {
    const { data, sortData, filtersData, search, searchParams, variables} = props
    return (
        <>
            <CustomMetas title={search || "Frankenspalter Onlineshop"}
                description={search}
                keywords={search}
                page_type="product.item"
            />

            <PLP key={JSON.stringify(searchParams)} plpData={props} searchParams={searchParams} message={data.products.items.length !== 0 ? 1 : 0} forSearch={true} />
        </>
    );
}

export default SearchPage;


export const getServerSideProps = async (context) => {


    const props = await getSearchData(context)

    return {
        props
    }
};