
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/catalogsearch/result/[slug]",
      function () {
        return require("private-next-pages/catalogsearch/result/[slug].jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/catalogsearch/result/[slug]"])
      });
    }
  